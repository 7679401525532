import React from "react";
import Landing from "./pages/Landing";
import "../src/styles/style.css";

export default function App () {

  return (
    <>
      <Landing></Landing>
    </>
  )
}